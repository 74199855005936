var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-layout-vue"},[_c('Navigation',{staticClass:"hide",attrs:{"show-user-menu":true,"show-white-logo":true}}),_c('div',{staticClass:"flex"},[_c('Sidebar'),_c('div',{staticClass:"w-screen items-center",class:_vm.checkLanguage},[(
          (_vm.whitelabel === 'ICMVC' || _vm.whitelabel === 'ICMMU') &&
          _vm.showIcmZendeskSnippet === 'version1'
        )?_c('script',{attrs:{"id":"ze-snippet","src":"https://static.zdassets.com/ekr/snippet.js?key=73872fca-8778-4236-b4d9-3ebdb45e021a","type":"application/javascript"}}):_vm._e(),(
          (_vm.whitelabel === 'ICMVC' || _vm.whitelabel === 'ICMMU') &&
          _vm.showIcmZendeskSnippet === 'version2'
        )?_c('script',{attrs:{"type":"application/javascript"}},[_vm._v(" window.__lc = window.__lc || {}; window.__lc.license = 10385472; (function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n); } var e = { _q: [], _h: null, _v: \"2.0\", on: function () { i([\"on\", c.call(arguments)]); }, once: function () { i([\"once\", c.call(arguments)]); }, off: function () { i([\"off\", c.call(arguments)]); }, get: function () { if (!e._h) throw new Error( \"[LiveChatWidget] You can't use getters before load.\" ); return i([\"get\", c.call(arguments)]); }, call: function () { i([\"call\", c.call(arguments)]); }, init: function () { var n = t.createElement(\"script\"); (n.async = !0), (n.type = \"text/javascript\"), (n.src = \"https://cdn.livechatinc.com/tracking.js\"), t.head.appendChild(n); }, }; !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e); })(window, document, [].slice); ")]):_vm._e(),(
          (_vm.whitelabel === 'ICMVC' || _vm.whitelabel === 'ICMMU') &&
          _vm.showIcmZendeskSnippet === 'version2'
        )?_c('noscript',[(
            (_vm.whitelabel === 'ICMVC' || _vm.whitelabel === 'ICMMU') &&
            _vm.showIcmZendeskSnippet === 'version2'
          )?_c('a',{attrs:{"href":"https://www.livechat.com/chat-with/10385472/","rel":"nofollow"}},[_vm._v("Chat with us")]):_vm._e(),_vm._v(", powered by "),(
            (_vm.whitelabel === 'ICMVC' || _vm.whitelabel === 'ICMMU') &&
            _vm.showIcmZendeskSnippet === 'version2'
          )?_c('a',{attrs:{"href":"https://www.livechat.com/?welcome","rel":"noopener nofollow","target":"_blank"}},[_vm._v("LiveChat")]):_vm._e()]):_vm._e(),_c('div',[_c('router-view',{staticClass:"flex-1",attrs:{"id":"router-view"}}),(this.$store.getters.get_annual_check)?_c('AnnualCheckModal'):_vm._e(),_c('Footer',{staticClass:"mx-auto"})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }