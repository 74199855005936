<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    aria-labelledby="modal-title"
    aria-modal="true"
    class="fixed z-10 inset-0 overflow-y-auto"
    role="dialog"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        aria-hidden="true"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        aria-hidden="true"
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
      >
        <!--      Forms-->
        <div class="max-w-3xl mx-auto min-h-screen">
          <div>
            <Form
              v-for="(step, index) in step_meta"
              v-if="index === $store.getters.show_next_step"
              :id="index"
              :key="index"
              :demo-form="false"
              :forms="step[0].fields"
              :numberOfSteps="step_meta.length - 1"
              :step="step"
              classes="shadow rounded-md"
              form-type="annual-check"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/views/register/Form";
import store from "@/store";

export default {
  name: "AnnualCheckModal",
  components: {
    Form,
  },
  computed: {
    step_meta() {
      const t = this.$t.bind(this);
      let fields = [
        [
          {
            kind: "title",
            type: "title",
            title: "Verify your personal details",
            subtitle: `Please review the details we have on file for you and confirm that our products continue to meet your needs.<br><br>Please amend any details that are no longer correct.`,
            fields: [
              {
                field: "addr_street",
                key: "addr_street",
                label: t("address-line-1"),
                placeholder: t("address-line-1"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "addr1",
              },
              {
                field: "addr_line_2",
                key: "addr_line_2",
                label: t("address-line-2"),
                placeholder: t("address-line-2"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "addr2",
              },
              {
                field: "addr_city",
                key: "addr_city",
                label: t("city-or-town"),
                placeholder: t("city-or-town"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "city",
              },
              {
                field: "addr_zip",
                key: "addr_zip",
                label: t("Postcode"),
                placeholder: t("Postcode"),
                kind: "postcode",
                type: "postcode",
                needed: true,
                not_country: "IE",
                fieldId: "postcode",
              },
              {
                field: "annual_check_country",
                key: "addr_country",
                label: t("Country"),
                placeholder: t("country-name"),
                kind: "select",
                type: "select",
                needed: true,
                country: true,
                fieldId: "country",
                choices: store.getters.get_countries,
              },
              {
                field: "telephone",
                key: "telephone",
                label: t("phone-number"),
                placeholder: t("phone-number"),
                kind: "telephone",
                type: "telephone",
                needed: true,
                fieldId: "phone",
              },
              {
                field: "radio",
                key: "option",
                label: `I understand the risks of leveraged trading and confirm that the products offered by ${process.env.VUE_APP_WHITELABEL} continue to meet my needs and investment goals.`,
                kind: "radio",
                type: "radio",
                needed: true,
                fieldId: "radio",
                choices: [
                  { value: "Agree", display_name: "Agree" },
                  { value: "Disagree", display_name: "Disagree" },
                ],
              },
            ],
          },
        ],
      ];
      return fields;
    },
  },
  created() {
    this.$store.dispatch("countries");
  },
};
</script>
